import React, { useState, useEffect, useRef } from 'react';
import orderIdIcon from '../assets/images/OrderId.svg';
import modalTimer from '../assets/images/time-green.svg';
import DeliveryIcon from '../assets/images/DeliveryStatus.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIndianRupeeSign, faMinus } from '@fortawesome/free-solid-svg-icons';
import './component.css';
import axios from 'axios';
import { io } from 'socket.io-client';
import Swal from 'sweetalert2';
import axiosURL from '../config';

const OngoingOrderCard = ({ index, orderId, dateTime, medicines, quantity, totalAmount, status }) => {

    totalAmount = parseFloat(totalAmount).toFixed(2);
    const platformFeePercentage = 5;
    const platformFee = (totalAmount * platformFeePercentage) / 100;
    const amountEarned = totalAmount - platformFee;

    const [showModal, setShowModal] = useState(false);
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [newStatus, setNewStatus] = useState(status);
    const socketRef = useRef(null);
    const [medicine, setMedicine] = useState([]);

    useEffect(() => {
        if (!socketRef.current) {
            socketRef.current = io('https://immuneapi-production-api.up.railway.app');
            socketRef.current.on('orderStatusChanged', (data) => {
                Swal.fire({
                    title: 'Order Status Changed',
                    text: `Order ${data.orderId} status has been updated to ${data.status}`,
                    icon: 'info',
                    confirmButtonText: 'OK',
                    timer: 2500
                });
                //setShowOrderModal(true);
            });
        }

        return () => {
            if (socketRef.current) {
                socketRef.current.off('orderStatusChanged');
                socketRef.current.disconnect();
                socketRef.current = null;
            }
        };
    }, []);

    useEffect(() => {
        const fetchAndSetProducts = async () => {
            if (medicines && Array.isArray(medicines)) {
                try {
                    await fetchProductDetails(medicines, quantity);
                } catch (error) {
                    console.error('Error processing products:', error);
                }
            }
        };
        fetchAndSetProducts();
    }, [medicines]);

    const fetchProductDetails = async (productIds, quantities) => {
        try {
            const productsData = [];
            for (let i = 0; i < productIds.length; i++) {
                const id = productIds[i];
                const quantity = quantities[i];
    
                const response = await axiosURL.get('product/getbyid', {
                    params: { id: id }
                });
    
                if (response.status === 200) {
                    const product = response.data[0];
                    product.Quantity = quantity;
                    productsData.push(product);
                } else {
                    console.error(`Failed to fetch details for product ID: ${id}`);
                }
            }
            setMedicine(productsData);
        } catch (error) {
            console.error('Error fetching product details:', error);
            setMedicine([]);
        }
    };    

    const handleViewOrder = () => {
        setShowModal(true);
    }

    const handleStatusOrder = () => {
        setShowStatusModal(true);
    }

    const handleStatusChange = async () => {
        try {
            const response = await axiosURL.post('order/status', {
                orderId,
                status: newStatus
            });

            if (response.data.status === 'success') {
                console.log('Status updated successfully');
                setShowModal(false);
                setShowStatusModal(false);
            } else {
                console.error('Status update failed:', response.data.message);
            }
        } catch (error) {
            console.error('Error updating status:', error.message);
        }
    };

    const handleBackdropClick = (event) => {
        if (event.target.classList.contains('modal')) {
            setShowModal(false);
            setShowStatusModal(false);
        }
    };

    return (
        <div>
            <div className="ongoing-order-card">
                <div className="ongoing-order-info">
                    <div className="ongoing-order-id">
                        <div className="ongoing-order-id-icon">
                            <img src={orderIdIcon} style={{ width: '30px', height: '30px' }} alt="Order ID Icon" />
                        </div>
                        <div>
                            <span>{orderId}</span>
                            <div className="ongoing-order-date-time">
                                <span style={{ fontSize: '14' }}>{dateTime}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', margin: '5px' }}>
                    <span className='OngoingOrderConfirm'>
                        <img src={DeliveryIcon} height={'15px'} /> Delivery Partner On its way
                    </span>
                </div>
                <button className="view-order-btn" onClick={handleViewOrder}>View Order</button>
            </div>
            {showModal && (
                <div className="modal" onClick={handleBackdropClick}>
                    <div className="modal-content">
                        <div className="modal-header" style={{ background: 'none' }}>
                            <div className="order-info">
                                <div className="order-id">
                                    <div className="order-id-icon">
                                        <img src={orderIdIcon} style={{ width: '30px', height: '30px' }} alt="Order ID Icon" />
                                    </div>
                                    <div>
                                        <span style={{ fontWeight: '600' }}>{orderId}</span>
                                        <div className="order-date-time" style={{ fontWeight: '600' }}>
                                            <span>{dateTime}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <table className="medicine-table">
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: 'left' }}>Items</th>
                                        <th>Quantity</th>
                                        <th>Price</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {medicine.map((medicine, index) => (
                                        <tr key={index}>
                                            <td style={{ textAlign: 'left' }}>{medicine.name}</td>
                                            <td>{medicine.Quantity}</td>
                                            <td>{medicine.MRP}</td>
                                            <td>{medicine.MRP}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className='custom-hr'></div>
                            <div className="modal-summary">
                                <div className="summary-item">
                                    <span>Total Amount:</span>
                                    <span>
                                        <FontAwesomeIcon icon={faIndianRupeeSign} /> {totalAmount}
                                    </span>
                                </div>
                                <div className="summary-item">
                                    <span>Platform Fee ({platformFeePercentage}%):</span>
                                    <span>
                                        <FontAwesomeIcon icon={faMinus} /> <FontAwesomeIcon icon={faIndianRupeeSign} /> {platformFee.toFixed(2)}
                                    </span>
                                </div>
                                <div className="summary-item">
                                    <span>Amount Earned:</span>
                                    <span style={{ fontSize: '24px', fontWeight: '700' }}>
                                        <FontAwesomeIcon icon={faIndianRupeeSign} />{amountEarned.toFixed(2)}
                                    </span>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <span className='OrderConfirm'>
                                        <img src={DeliveryIcon} height={'20px'} />Delivery Partner On its way
                                    </span>
                                </div>
                            </div>
                            <button className="request-accept-btn" onClick={handleStatusOrder}>Change Status of Order</button>
                        </div>
                    </div>
                </div>
            )}
            {showStatusModal && (
                <div className="modal mini-modal" onClick={handleBackdropClick}>
                    <div className="modal-content mini-modal-content">
                        <div className="modal-header mini-modal-header">
                            <h5>Change Order Status</h5>
                        </div>
                        <div className="modal-body mini-modal-body">
                            <div>
                                <label htmlFor="status">Select Status:</label>
                                <select
                                    id="status"
                                    value={newStatus}
                                    onChange={(e) => setNewStatus(e.target.value)}
                                >
                                    <option value="4">Handover to Delivery Partner</option>
                                </select>
                            </div>
                            <button className="confirm-status-btn" onClick={handleStatusChange}>
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default OngoingOrderCard;