import React, { useEffect, useRef, useState } from 'react';
import '../pharma.css';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import logo from "../assets/images/logo2.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import notification from "../assets/images/Notification.png";
import { faUser, faChevronDown, faUserCircle, faCog, faSignOutAlt, faShoppingCart, faMoneyCheckAlt, faTimes, faInfoCircle, faShieldAlt, faFileContract } from '@fortawesome/free-solid-svg-icons';
import { RxDashboard } from 'react-icons/rx';
import axios from 'axios';
import io from 'socket.io-client';
import axiosURL from '../config';

function MedicalDashboard({ isLoggedIn }) {
    const [pharmaId, setPharmaId] = useState(localStorage.getItem('pharmaId') || '');
    const [pharmaDetails, setPharmaDetails] = useState({});
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [activeButton, setActiveButton] = useState('dashboard');
    const [notifications, setNotifications] = useState([]);
    const today = new Date().toLocaleDateString();
    const socketRef = useRef(null);

    const navigate = useNavigate();

    useEffect(() => {
        if (!socketRef.current) {
            socketRef.current = io('https://immuneapi-production-api.up.railway.app');
            socketRef.current.on('orderStatusChanged', async (data) => {
                const { orderId, status, updatedOrder } = data;
                // Handle the notification
                setNotifications(prevNotifications => [
                    `Order ${orderId} status changed to ${status === 1 ? 'Paid' : 'Unpaid'}`,
                    ...prevNotifications
                ]);
            });
        }

        return () => {
            if (socketRef.current) {
                socketRef.current.off('orderStatusChanged');
                socketRef.current.disconnect();
                socketRef.current = null;
            }
        };
    }, []);

    useEffect(() => {
        // Handle background color and login status
        const checkLoginStatus = () => {
            const storedLoginStatus = localStorage.getItem('isLoggedIn');
            if (storedLoginStatus !== 'true') {
                navigate('/login');
            }
        };

        const fetchPharmaDetails = async () => {
            try {
                if (!pharmaId) {
                    console.error('No Pharma ID found in local storage');
                    return;
                }
                const url = `pharma/getPharmaById?id=${pharmaId}`;
                const response = await axiosURL.get(url);
                setPharmaDetails(response.data[0]);
            } catch (error) {
                console.error('Error fetching pharma details:', error);
            }
        };

        checkLoginStatus();
        fetchPharmaDetails();
    }, [isLoggedIn, navigate]);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const toggleNotification = () => {
        setIsNotificationOpen(!isNotificationOpen);
    };

    const clearNotifications = () => {
        setNotifications([]);
    };

    const handleOptionClick = (option) => {
        setIsDropdownOpen(false);
        switch (option) {
            case 'logout':
                handleLogout();
                break;
            case 'profile':
                navigate('/medical-dashboard/profile');
                break;
            case 'about':
                navigate('/medical-dashboard/about');
                break;
            case 'privacy':
                navigate('/medical-dashboard/privacy');
                break;
            case 'terms&conditions':
                navigate('/medical-dashboard/terms&conditions');
                break;
            default:
                console.warn(`Unknown option: ${option}`);
                break;
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('userType');
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('pharmaId');
        navigate('/login');
    };

    return (
        <div>
            <nav className="navbar">
                <div className="navbar-top">
                    <div className="navbar-logo">
                        <img src={logo} className="logo" alt="Logo" />
                    </div>
                    <div className="navbar-content">
                        <div className="navbar-date" style={{ fontWeight: 'bold', marginRight: '20px' }}>{today}</div>
                        <div className="navbar-icons">
                            <img src={notification} alt="Notifications" style={{ height: '36px', marginRight: '15px', cursor: 'pointer' }} onClick={toggleNotification} />
                            <div onClick={toggleDropdown} style={{ cursor: 'pointer' }}>
                                <span className="user-name" style={{ fontWeight: 'bold', marginRight: '10px' }} >{pharmaDetails.name}</span>
                                <FontAwesomeIcon
                                    icon={faChevronDown}
                                    className="dropdown-icon"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </nav>

            
            {isDropdownOpen && (
                <div className="dropdown-menu">
                    <div className="dropdown-option" onClick={() => handleOptionClick('profile')}>
                        <FontAwesomeIcon icon={faUserCircle} className="dropdown-icon" />
                        <span>Profile</span>
                    </div>
                    <div className="dropdown-option" onClick={() => handleOptionClick('about')}>
                        <FontAwesomeIcon icon={faInfoCircle} className="dropdown-icon" />
                        <span>About Us</span>
                    </div>
                    <div className="dropdown-option" onClick={() => handleOptionClick('privacy')}>
                        <FontAwesomeIcon icon={faShieldAlt} className="dropdown-icon" />
                        <span>Privacy Policy</span>
                    </div>
                    <div className="dropdown-option" onClick={() => handleOptionClick('terms&conditions')}>
                        <FontAwesomeIcon icon={faFileContract} className="dropdown-icon" />
                        <span>Terms & Condition</span>
                    </div>
                    <div className="dropdown-option" onClick={() => handleOptionClick('logout')}>
                        <FontAwesomeIcon icon={faSignOutAlt} className="dropdown-icon" />
                        <span>Logout</span>
                    </div>
                </div>
            )}

            <nav className="navbar-bottom-green">
                <NavLink
                    to="/medical-dashboard/dashboard"
                    className={({ isActive }) => `nav-button ${isActive ? 'active' : ''}`}
                >
                    <RxDashboard className="nav-icon" />
                    Dashboard
                </NavLink>
                <NavLink
                    to="/medical-dashboard/myorder"
                    className={({ isActive }) => `nav-button ${isActive ? 'active' : ''}`}
                >
                    <FontAwesomeIcon icon={faShoppingCart} className="nav-icon" />
                    My Orders
                </NavLink>
                <NavLink
                    to="/medical-dashboard/payment"
                    className={({ isActive }) => `nav-button ${isActive ? 'active' : ''}`}
                >
                    <FontAwesomeIcon icon={faMoneyCheckAlt} className="nav-icon" />
                    Payments
                </NavLink>
            </nav>

            {isNotificationOpen && (
                <div className="notification-modal">
                    <div className="modal-header">
                        <span>Notifications</span>
                        <FontAwesomeIcon
                            icon={faTimes}
                            className="close-icon"
                            onClick={toggleNotification}
                        />
                    </div>
                    <div className="modal-body">
                        {notifications.length > 0 ? (
                            notifications.map((notification, index) => (
                                <div key={index} className="notification-item">
                                    {notification}
                                </div>
                            ))
                        ) : (
                            <div>No notifications</div>
                        )}
                    </div>
                    <div className="modal-footer">
                        <button onClick={clearNotifications} className="clear-button">Clear All</button>
                    </div>
                </div>
            )}
            <div className="content">
                <Outlet />
            </div>
        </div>
    );
}

export default MedicalDashboard;
